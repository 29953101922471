<template>
    <div style="text-align: center; width:530px; margin: 0 auto;font-family: 'Noto Sans KR', '맑은 고딕', 'Malgun Gothic', 'dotum';
    font-weight:bold;font-size: 15px;margin-top:12%;letter-spacing: -1px;color:#555;height:567px;">
        <p><img src="../assets/img/etners-main/error_notice_02.png"  width="101" height="73" alt=""/> </p>
        <p style="font-size: 32px;color:#333;line-height: 18px;padding-top:20px;">페이지를 찾을 수 없습니다.</p>
        <p style="padding-top:20px;">입력하신 주소가 잘못 입력되었거나, 변경 또는 삭제되어 페이지를 찾을 수 없습니다. <br>
            입력하신 주소가 정확한지 다시 한번 확인해주세요.</p>
      <!--  <p>기타 문의사항은 <a href="#" style="color:#F86E30">고객센터</a>로 문의해 주시기 바랍니다.</p>-->
        <p style="margin:auto;margin-top: 30px; width:200px;">
            <button class="large_btn orange" @click="$router.push({name: 'DashBoard'})">메인으로</button>
        </p>
    </div>
</template>

<script>
  export default {
    name: 'NotFound',
  }
</script>
